import { lazy } from "react";

import { PageAtRouteMap } from "@/pages/pages.types";

export const ChatPage = lazy(() => import("@/pages/chat-page"));

/**
 * Create an object where routes are associated the their page components
 */
export const pageAtRouteMap = {
    home: ChatPage,
    chat: ChatPage,
} satisfies Partial<PageAtRouteMap>;
