import { z } from "zod";

// eslint-disable-next-line no-restricted-imports
import packageJSON from "../package.json";

const environments = z.enum(["production", "acceptance", "test", "development"]);
export type ENVIRONMENTS = z.infer<typeof environments>;

const stringSchema = z.string({ required_error: "Not defined" });
const urlSchema = stringSchema.url().refine((val) => val.at(-1) === "/", {
    message: "Url should end with the character '/'",
});

const configSchema = z.object({
    /*
     * APP_ENV: Enum
     * Specifies the application's runtime environment.
     * Required: Yes | Default: "production"
     * Example (.env): VITE_APP_ENV=production
     */
    APP_ENV: environments.default("production"),

    /*
     * APP_NAME: String
     * Specifies the application's name.
     * Required: Yes | Default: "R&D Driver"
     * Example (.env): VITE_APP_NAME=R&D Driver
     */
    APP_NAME: stringSchema.default("R&D Driver"),
    /*
     * APP_VERSION: String
     * Specifies the application's version.
     * Required: Yes | Default: "v0.0.0"
     * Example (.env): VITE_APP_VERSION
     */
    APP_VERSION: stringSchema.default(`v${packageJSON.version}`),

    /*
     * API_BASE_URL: String URL
     * Specifies the URL of the API.
     * Required: Yes
     * Example (.env): VITE_API_BASE_URL=http://localhost:3000/api
     */
    API_BASE_URL: urlSchema,

    /*
     * AZURE_AD_AUTH_AUTHORITY: String URL
     * Specifies the authority of the Azure AD application.
     * Required: Yes
     * Example (.env): VITE_AZURE_AD_AUTH_AUTHORITY=https://login.microsoftonline.com/your-tenant-id
     */
    AZURE_AUTH_AUTHORITY_URL: stringSchema,

    /*
     * AZURE_AD_AUTH_CLIENT_ID: String UUID
     * Specifies the client ID of the Azure AD application.
     * Required: Yes
     * Example (.env): VITE_AZURE_AD_AUTH_CLIENT_ID=your-client-id
     */
    AZURE_AUTH_CLIENT_ID: stringSchema.uuid(),

    /*
     * AZURE_AD_AUTH_SCOPE: String
     * Specifies the allowed scope of access of the Azure AD application.
     * Required: Yes
     * Example (.env): VITE_AZURE_AD_AUTH_SCOPE=your-client-id/all
     */
    AZURE_AUTH_SCOPE: stringSchema,

    /*
     * AZURE_AD_AUTH_REDIRECT_URL: String URL
     * Specifies the URL to redirect to after successful login.
     * Required: Yes
     * Example (.env): VITE_AZURE_AD_AUTH_REDIRECT_URL=http://localhost:5173
     */
    AZURE_AUTH_REDIRECT_URL: stringSchema.url(),

    /*
     * AZURE_AD_AUTH_POST_LOGOUT_REDIRECT_URL: String URL
     * Specifies the URL to redirect to after logout.
     * Required: No
     * Example (.env): VITE_AZURE_AD_AUTH_POST_LOGOUT_REDIRECT_URL=http://localhost:5173
     */
    AZURE_AUTH_POST_LOGOUT_REDIRECT_URL: stringSchema.url().optional(),
});

let config: z.infer<typeof configSchema>;
const result = configSchema.safeParse({
    APP_ENV: import.meta.env.VITE_APP_ENV,
    APP_NAME: import.meta.env.VITE_APP_NAME,
    APP_VERSION: import.meta.env.VITE_APP_VERSION,
    API_BASE_URL: import.meta.env.VITE_API_BASE_URL,
    AZURE_AUTH_AUTHORITY_URL: import.meta.env.VITE_AZURE_AUTH_AUTHORITY_URL,
    AZURE_AUTH_CLIENT_ID: import.meta.env.VITE_AZURE_AUTH_CLIENT_ID,
    AZURE_AUTH_SCOPE: import.meta.env.VITE_AZURE_AUTH_SCOPE,
    AZURE_AUTH_REDIRECT_URL: import.meta.env.VITE_AZURE_AUTH_REDIRECT_URL,
    AZURE_AUTH_LOGOUT_REDIRECT_URL: import.meta.env.VITE_AZURE_AUTH_POST_LOGOUT_REDIRECT_URL,
});

class EnvironmentVariablesError extends Error {
    name = "EnvironmentVariablesError";

    constructor(errors: Record<string, string[]>) {
        const message = Object.entries(errors)
            .map(([key, errors]) => `${key}: ${errors.join(", ")}`)
            .join("\n");

        super(`\n\nErrors while validating environment variables:\n\n${message}\n\n`);
    }
}

if (result.success) {
    config = result.data;
} else {
    const errors = result.error.flatten();
    throw new EnvironmentVariablesError(errors.fieldErrors);
}

export { config };
