import { AzureAuthConfiguration, AzureAuthProvider } from "@datamole/wds-azure-auth";
import { Tooltip } from "@datamole/wds-component-tooltip";
import { ErrorBoundary } from "react-error-boundary";
import { Provider } from "react-redux";
import { BrowserRouter } from "react-router-dom";

import { ErrorFallback } from "@/components/error-fallback";
import { config } from "@/config";
import { AppRoutes } from "@/routes/app-routes";
import { store } from "@/store";

import "@datamole/wds-css-normalize";
import "@datamole/wds-css-theme-fonterra";
import "@/styles/global.css";

const azureAuthConfiguration: AzureAuthConfiguration = {
    authorityUrl: config.AZURE_AUTH_AUTHORITY_URL,
    clientId: config.AZURE_AUTH_CLIENT_ID,
    postLogoutRedirectUrl: config.AZURE_AUTH_POST_LOGOUT_REDIRECT_URL,
    redirectUrl: config.AZURE_AUTH_REDIRECT_URL,
};

/**
 * Component representing the main application
 *
 * @returns rendered application
 */
const App = () => {
    return (
        <Provider store={store}>
            <ErrorBoundary fallback={<ErrorFallback />}>
                <BrowserRouter>
                    <AzureAuthProvider configuration={azureAuthConfiguration}>
                        <Tooltip.Provider>
                            <AppRoutes />
                        </Tooltip.Provider>
                    </AzureAuthProvider>
                </BrowserRouter>
            </ErrorBoundary>
        </Provider>
    );
};

export { App };
