import { acquireAccessToken, getAzureAuthInstance } from "@datamole/wds-azure-auth";
import { BaseQueryFn, FetchArgs, fetchBaseQuery, FetchBaseQueryError } from "@reduxjs/toolkit/query";
import { Mutex } from "async-mutex";

import { config } from "@/config";
import type { AppDispatch, RootState } from "@/store";
import { clearAuth, setAuth } from "@/store/slices/auth.slice";

const mutex = new Mutex();

const baseQuery = fetchBaseQuery({
    baseUrl: config.API_BASE_URL,
    prepareHeaders: (headers, api) => {
        const state = api.getState() as RootState;
        const accessToken = state.auth.accessToken;
        if (accessToken) {
            headers.set("authorization", `Bearer ${accessToken}`);
        }
        return headers;
    },
});

/**
 * Extend fetchBaseQuery which automatically tries to acquire a new accessToken when 401 error is received.
 * with using async-mutex to prevent multiple acquiringTokens attempt when multiple calls fail with 401 Unauthorized errors.
 * source: https://redux-toolkit.js.org/rtk-query/usage/customizing-queries#preventing-multiple-unauthorized-errors
 * @param args
 * @param api
 * @param extraOptions
 */
const baseQueryWithReAuth: BaseQueryFn<string | FetchArgs, unknown, FetchBaseQueryError> = async (
    args,
    api,
    extraOptions,
) => {
    // wait until the mutex is available without locking it
    await mutex.waitForUnlock();
    let result = await baseQuery(args, api, extraOptions);
    if (!result.error || result.error.status !== 401) {
        return result;
    }

    if (!mutex.isLocked()) {
        await lockMutexAndRevalidate(api.dispatch);
    } else {
        await mutex.waitForUnlock();
    }
    result = await baseQuery(args, api, extraOptions);
    return result;
};

const lockMutexAndRevalidate = async (dispatch: AppDispatch) => {
    const release = await mutex.acquire();

    const azureAdInstance = await getAzureAuthInstance();

    try {
        const accessToken = await acquireAccessToken(
            {
                scopes: [config.AZURE_AUTH_SCOPE],
            },
            azureAdInstance,
        );
        if (accessToken) {
            dispatch(setAuth({ accessToken }));
        } else {
            dispatch(clearAuth());
            throw new Error("Authentication failed");
        }
    } finally {
        release();
    }
};

export { baseQueryWithReAuth };
