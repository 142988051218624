import { Sidebar as WdsSidebar } from "@datamole/wds-component-sidebar";
import { faMessage } from "@fortawesome/pro-regular-svg-icons";
import { FontAwesomeIcon } from "@fortawesome/react-fontawesome";
import { NavLink } from "react-router-dom";

import { generatePath, routes } from "@/routes";

const CHAT_HISTORY = [
    {
        sessionId: 1,
        name: "Chat 1",
    },
    {
        sessionId: 2,
        name: "Chat 2",
    },
    {
        sessionId: 3,
        name: "Chat 3",
    },
];

const ChatHistory = () => {
    const chatHistory = CHAT_HISTORY; // TODO: Replace with actual chat history

    return (
        <>
            {chatHistory.map((chat) => (
                <WdsSidebar.Item asChild key={chat.sessionId}>
                    <NavLink
                        to={generatePath(routes.chat.path, {
                            sessionId: chat.sessionId.toString(),
                        })}
                    >
                        <WdsSidebar.Item.Icon>
                            <FontAwesomeIcon icon={faMessage} />
                        </WdsSidebar.Item.Icon>
                        <WdsSidebar.Item.Content>{chat.name}</WdsSidebar.Item.Content>
                    </NavLink>
                </WdsSidebar.Item>
            ))}
        </>
    );
};

export { ChatHistory };
