import { Suspense } from "react";
import { Outlet } from "react-router-dom";

import styles from "@/components/layout/layout.module.css";
import { Sidebar } from "@/components/layout/sidebar";

export const Layout = () => {
    return (
        <div className={styles.layout}>
            <Sidebar />
            <div>
                <Suspense>
                    <Outlet />
                </Suspense>
            </div>
        </div>
    );
};
