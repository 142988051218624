import { Link } from "react-router-dom";

import { routes } from "@/routes";

export const PageNotFoundPage = () => {
    return (
        <div>
            <h1>Not found</h1>
            <p>
                Page you are looking for is not here. <Link to={routes.home.path}>Go back to chat?</Link>
            </p>
        </div>
    );
};
