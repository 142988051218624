import {
    InteractionType,
    acquireAccessToken,
    useAzureAuth,
    MsalAuthenticationTemplate,
} from "@datamole/wds-azure-auth";
import { ReactNode, useCallback, useEffect } from "react";

import { config } from "@/config";
import { useAppDispatch, useAppSelector } from "@/store";
import { setAuth } from "@/store/slices/auth.slice";

/**
 * Set preferred interaction type for token acquisition
 * Choose Redirect or Popup
 */
const INTERACTION_TYPE = InteractionType.Redirect;

interface Props {
    children: ReactNode;
}

export const AuthGuard = ({ children }: Props) => {
    const accessToken = useAppSelector((state) => state.auth.accessToken);
    const dispatch = useAppDispatch();

    const { instance, isInteractionInProgress } = useAzureAuth({
        interactionType: INTERACTION_TYPE,
    });

    const acquireAndPersistAccessToken = useCallback(async () => {
        const accessToken = await acquireAccessToken(
            {
                scopes: [config.AZURE_AUTH_SCOPE],
            },
            instance,
        );

        dispatch(setAuth({ accessToken }));
    }, [instance, dispatch]);

    const LoadingComponent = () => {
        return null;
    };

    useEffect(() => {
        /**
         * If user is already logged in or interaction is in progress, do nothing
         */
        if (accessToken || isInteractionInProgress) return;

        // eslint-disable-next-line @typescript-eslint/no-floating-promises
        acquireAndPersistAccessToken();
    }, [accessToken, isInteractionInProgress, acquireAndPersistAccessToken]);

    return (
        <MsalAuthenticationTemplate interactionType={INTERACTION_TYPE} loadingComponent={LoadingComponent}>
            {children}
        </MsalAuthenticationTemplate>
    );
};
