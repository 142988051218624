import { Routes, Route } from "react-router-dom";

import { AuthGuard } from "@/components/auth-guard";
import { Layout } from "@/components/layout";
import { pageAtRouteMap } from "@/pages";
import PageNotFoundPage from "@/pages/page-not-found-page";
import { routeNames, routes } from "@/routes";

/**
 * Create an array of objects with path and the component properties
 */
const pages = routeNames.map((routeName) => {
    const Component = pageAtRouteMap[routeName];
    return { path: routes[routeName].path, Component };
});

export const AppRoutes = () => (
    <Routes>
        <Route
            element={
                <AuthGuard>
                    <Layout />
                </AuthGuard>
            }
        >
            {pages.map(({ path, Component }) => (
                <Route key={path} path={path} element={<Component />} />
            ))}
            <Route key="404" path="*" element={<PageNotFoundPage />} />
        </Route>
    </Routes>
);
