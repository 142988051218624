import { Sidebar as WdsSidebar } from "@datamole/wds-component-sidebar";

import { ChatHistory } from "@/components/layout/sidebar/chat-history";
import { Header } from "@/components/layout/sidebar/header";
import { SidebarItem } from "@/components/layout/sidebar/sidebar-item";
import { sidebarItems } from "@/components/layout/sidebar/sidebar-items";
import styles from "@/components/layout/sidebar/sidebar.module.css";
import { UserMenu } from "@/components/layout/sidebar/user-menu/user-menu";

export const Sidebar = () => {
    return (
        <WdsSidebar className={styles.sidebar}>
            <WdsSidebar.Content>
                <Header />

                <SidebarItem {...sidebarItems.home} />

                <WdsSidebar.Subtitle>Chat History</WdsSidebar.Subtitle>
                <ChatHistory />
            </WdsSidebar.Content>
            <WdsSidebar.Content>
                <UserMenu />
            </WdsSidebar.Content>
        </WdsSidebar>
    );
};
