import { faHome } from "@fortawesome/pro-regular-svg-icons";
import { FontAwesomeIcon } from "@fortawesome/react-fontawesome";
import { ReactNode } from "react";

import { RoutesPaths, routes } from "@/routes";

export interface SidebarLinkItem {
    name: string;
    to: RoutesPaths;
    icon?: ReactNode;
}

// Defines contents for Sidebar menu
export const sidebarItems = {
    home: {
        to: routes.home.path,
        name: "Home",
        icon: <FontAwesomeIcon icon={faHome} />,
    },
} satisfies Record<string, SidebarLinkItem>;
